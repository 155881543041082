<template>
  <ui-component-modal
    modalTitle=""
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteVoucher"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        {{
          $t(
            `Components.Vouchers.DeleteVoucher.Message_AreYouSureVoucherDelete`
          )
        }}
      </div>

      <div class="has-text-centered has-margin-top">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="
            $t(`Components.Vouchers.DeleteVoucher.Checkbox_Option_YesImSure`)
          "
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import voucherProvider from '@/providers/voucher'

const BaseCheckbox = () =>
  import(
    /* webpackChunkName: "settings-options" */ '@/components/UI/Form/BaseCheckbox'
  )

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    channelId: {
      type: Number,
      default: 0,
    },

    locationId: {
      type: Number,
      default: 0,
    },

    voucher: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
      isDeleteConfirmed: false,
      mVoucher: null,
    }
  },

  computed: {},

  created() {
    this.mVoucher = JSON.parse(JSON.stringify(this.voucher))
  },

  methods: {
    deleteVoucher() {
      if (this.locationId === 0) {
        this.deleteChannelVoucher()
      } else {
        this.deleteLocationVoucher()
      }
    },

    deleteChannelVoucher() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        voucherProvider.methods
          .deleteChannelVoucher(this.mVoucher.Id, this.channelId)
          .then((response) => {
            this.isDeletingSuccess = true

            if (response.status === 204) {
              this.$emit('voucherDeleted', null)
              this.onClickCancel()
            }
          })
          .catch((error) => {
            this.isDeletingError = true
          })
          .finally(() => {
            this.isDeleting = false
          })
      }
    },

    deleteLocationVoucher() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        voucherProvider.methods
          .deleteVoucherLocation(this.mVoucher.Id, this.locationId)
          .then((response) => {
            this.isDeletingSuccess = true

            if (response.status === 200) {
              setTimeout(() => {
                this.$emit('voucherDeleted', response.data)
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            this.isDeletingError = true
          })
          .finally(() => {
            this.isDeleting = false
          })
      }
    },
  },
}
</script>
